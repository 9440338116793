import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Calendar, Plus, Minus } from "lucide-react";


const BookingForm = () => {
  const form = useRef();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    arrivalDate: "",
    departureDate: "",
  
    adults: 1,
    childrenUnder11: 0,
    childrenUnder5: 0,
    comments: "",
  });

  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Valid email is required";
    }

    // Phone validation
    const phoneRegex = /^\d{10}$/;
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) {
      newErrors.phone = "Valid 10-digit phone number is required";
    }

    // Address validation
    if (!formData.address.trim()) {
      newErrors.address = "Address is required";
    }

    // Date validations
    if (!formData.arrivalDate) {
      newErrors.arrivalDate = "Arrival date is required";
    }
    if (!formData.departureDate) {
      newErrors.departureDate = "Departure date is required";
    }
    if (
      formData.arrivalDate &&
      formData.departureDate &&
      new Date(formData.arrivalDate) > new Date(formData.departureDate)
    ) {
      newErrors.departureDate = "Departure date must be after arrival date";
    }

    

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleNumberChange = (field, increment) => {
    setFormData((prev) => ({
      ...prev,
      [field]: Math.max(0, prev[field] + increment),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setSubmitStatus({
        type: "error",
        message: "Please fix the errors in the form",
      });
      return;
    }

    setLoading(true);
    try {
      await emailjs.sendForm(
        "service_dzf5aim",
        "template_0rhtnd6",
        form.current,
        "ifO4x0HI6UYsnUtQV"
      );
      setSubmitStatus({
        type: "success",
        message: "Booking request sent successfully!",
      });
      // Reset form
      setFormData({
        name: "",
        email: "",
        phone: "",
        address: "",
        arrivalDate: "",
        departureDate: "",
      
        adults: 1,
        childrenUnder11: 0,
        childrenUnder5: 0,
        comments: "",
      });
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "Failed to send booking request. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <div className="max-w-[1400px] mx-auto px-4 py-16">
      <h2 className="text-4xl font-bold text-center mb-12 text-white">
        Book Your Trip
      </h2>

      <form
        ref={form}
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto"
      >
        {/* Personal Information */}
        <div className="space-y-4 md:col-span-2">
          <h3 className="text-xl font-semibold text-white mb-4">
            Personal Information
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full Name"
                className={`w-full px-4 py-2 rounded-lg bg-white/10 border ${
                  errors.name ? "border-red-500" : "border-white/20"
                } text-white placeholder-white/60 focus:outline-none focus:border-white`}
              />
              {errors.name && (
                <p className="text-red-400 text-sm mt-1">{errors.name}</p>
              )}
            </div>

            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Address"
                className={`w-full px-4 py-2 rounded-lg bg-white/10 border ${
                  errors.email ? "border-red-500" : "border-white/20"
                } text-white placeholder-white/60 focus:outline-none focus:border-white`}
              />
              {errors.email && (
                <p className="text-red-400 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <div>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number"
                className={`w-full px-4 py-2 rounded-lg bg-white/10 border ${
                  errors.phone ? "border-red-500" : "border-white/20"
                } text-white placeholder-white/60 focus:outline-none focus:border-white`}
              />
              {errors.phone && (
                <p className="text-red-400 text-sm mt-1">{errors.phone}</p>
              )}
            </div>

            <div>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address"
                className={`w-full px-4 py-2 rounded-lg bg-white/10 border ${
                  errors.address ? "border-red-500" : "border-white/20"
                } text-white placeholder-white/60 focus:outline-none focus:border-white`}
              />
              {errors.address && (
                <p className="text-red-400 text-sm mt-1">{errors.address}</p>
              )}
            </div>
          </div>
        </div>

        {/* Trip Details */}
        <div className="space-y-4 md:col-span-2">
          <h3 className="text-xl font-semibold text-white mb-4">
            Trip Details
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <div className="relative">
                <Calendar
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-white/60"
                  size={20}
                />
                <input
                  type="date"
                  name="arrivalDate"
                  value={formData.arrivalDate}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 pl-10 rounded-lg bg-white/10 border ${
                    errors.arrivalDate ? "border-red-500" : "border-white/20"
                  } text-white focus:outline-none focus:border-white`}
                />
              </div>
              {errors.arrivalDate && (
                <p className="text-red-400 text-sm mt-1">
                  {errors.arrivalDate}
                </p>
              )}
            </div>

            <div>
              <div className="relative">
                <Calendar
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-white/60"
                  size={20}
                />
                <input
                  type="date"
                  name="departureDate"
                  value={formData.departureDate}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 pl-10 rounded-lg bg-white/10 border ${
                    errors.departureDate ? "border-red-500" : "border-white/20"
                  } text-white focus:outline-none focus:border-white`}
                />
              </div>
              {errors.departureDate && (
                <p className="text-red-400 text-sm mt-1">
                  {errors.departureDate}
                </p>
              )}
            </div>

            

            
          </div>
        </div>

        {/* Travelers */}
        <div className="space-y-4 md:col-span-2">
          <h3 className="text-xl font-semibold text-white mb-4">Travelers</h3>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex items-center justify-between p-4 rounded-lg bg-white/10 border border-white/20">
              <span className="text-white">Adults</span>
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  onClick={() => handleNumberChange("adults", -1)}
                  className="p-1 rounded-full hover:bg-white/20"
                  disabled={formData.adults <= 1}
                >
                  <Minus size={20} className="text-white" />
                </button>
                <span className="text-white w-8 text-center">
                  {formData.adults}
                </span>
                <button
                  type="button"
                  onClick={() => handleNumberChange("adults", 1)}
                  className="p-1 rounded-full hover:bg-white/20"
                >
                  <Plus size={20} className="text-white" />
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between p-4 rounded-lg bg-white/10 border border-white/20">
              <span className="text-white">Children (Under 11)</span>
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  onClick={() => handleNumberChange("childrenUnder11", -1)}
                  className="p-1 rounded-full hover:bg-white/20"
                  disabled={formData.childrenUnder11 <= 0}
                >
                  <Minus size={20} className="text-white" />
                </button>
                <span className="text-white w-8 text-center">
                  {formData.childrenUnder11}
                </span>
                <button
                  type="button"
                  onClick={() => handleNumberChange("childrenUnder11", 1)}
                  className="p-1 rounded-full hover:bg-white/20"
                >
                  <Plus size={20} className="text-white" />
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between p-4 rounded-lg bg-white/10 border border-white/20">
              <span className="text-white">Children (Under 5)</span>
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  onClick={() => handleNumberChange("childrenUnder5", -1)}
                  className="p-1 rounded-full hover:bg-white/20"
                  disabled={formData.childrenUnder5 <= 0}
                >
                  <Minus size={20} className="text-white" />
                </button>
                <span className="text-white w-8 text-center">
                  {formData.childrenUnder5}
                </span>
                <button
                  type="button"
                  onClick={() => handleNumberChange("childrenUnder5", 1)}
                  className="p-1 rounded-full hover:bg-white/20"
                >
                  <Plus size={20} className="text-white" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Comments */}
        <div className="md:col-span-2 mt-6">
          <h3 className="text-xl font-semibold text-white mb-4">
            Additional Comments
          </h3>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            placeholder="Share any special requirements or questions..."
            rows={4}
            className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/60 focus:outline-none focus:border-white resize-none"
          />
        </div>

        {/* Submit Button and Status Messages */}
        <div className="md:col-span-2 mt-8">
          {submitStatus && (
            <div
              className={`p-4 rounded-lg mb-4 ${
                submitStatus.type === "success"
                  ? "bg-green-500/20 text-green-200"
                  : "bg-red-500/20 text-red-200"
              }`}
            >
              {submitStatus.message}
            </div>
          )}

          <button
            type="submit"
            disabled={loading}
            className="w-full py-3 px-6 rounded-lg bg-white text-teal-800 font-semibold hover:bg-white/90 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <div className="flex items-center justify-center gap-2">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-teal-800"></div>
                Sending...
              </div>
            ) : (
              "Book Now"
            )}
          </button>
        </div>
      </form>
    </div>
  
     </>
  );
};

export default BookingForm;
