import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderAboutUS from "../Header";
import placeholderImg from "../../../assets/background.jpg";
import ayuobowanImg from "../../../assets/Aubowan.png";
import vehicleImg from "../../../assets/vehical.jpg";
import FooterSection from "../LoadinPage/Sections-LoadingPage/FooterSection";
import DirectorsSection from "./DirectorsSection";

const AboutUs = () => {
  const [hoveredDivision, setHoveredDivision] = useState(null);

  return (
    <>
      <div className="w-full min-h-screen">
        <HeaderAboutUS />

        {/* Hero Section - Fully Responsive */}
        <div
          className="relative h-48 sm:h-64 md:h-96 w-full bg-cover bg-center flex items-center justify-center transform transition-transform duration-700"
          style={{ backgroundImage: `url(${placeholderImg})` }}
        >
          <div className="absolute inset-0 bg-black/50" />
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white z-10">
            About Us
          </h1>
        </div>

        {/* Main Content Section - Responsive Grid */}
        <div className="max-w-7xl mx-auto px-4 py-12 sm:py-16 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
          {/* Text Content */}
          <div className="space-y-6 sm:space-y-8 transform transition-all duration-500 text-center lg:text-left">
            <div>
              <h2 className="text-3xl sm:text-4xl font-bold mb-4">AUBOWAN!</h2>
              <h3 className="text-2xl sm:text-3xl font-semibold mb-6">
                We are RAJARATA
                <br />
                Tropical Ceylon Tours
              </h3>
            </div>

            <p className="text-gray-700 leading-relaxed">
              Welcome to Rajarata Tropical Ceylon Tours, a proud venture of the
              Rajarata Group of Companies. With a passion for showcasing the
              unparalleled beauty and rich cultural heritage of Sri Lanka, we
              specialize in crafting unforgettable travel experiences tailored
              to each traveler's desires.
            </p>

            <p className="text-gray-700 leading-relaxed">
              From serene beaches and lush plantations to ancient landmarks and
              vibrant local traditions, Rajarata Tropical Ceylon Tours invites
              you to embark on an adventure that blends discovery, relaxation,
              and cultural immersion. Experience Sri Lanka with us—where every
              journey tells a story.
            </p>
          </div>

          {/* Images Column - Responsive */}
          <div className="space-y-6 flex flex-col items-center relative">
            <div className="w-full px-4 lg:px-0 flex flex-col items-center lg:block relative lg:static">
              {/* Team Image */}
              <div className="w-full max-w-sm lg:w-3/5 lg:absolute lg:top-0 lg:right-0 rounded-3xl overflow-hidden transform transition-all duration-500 hover:scale-105 hover:shadow-2xl z-0">
                <img
                  src={ayuobowanImg}
                  alt="Our Team"
                  className="w-full h-auto object-cover"
                />
              </div>

              {/* Vehicles Image */}
              <div className="w-full max-w-sm lg:w-3/5 lg:absolute lg:top-1/2 lg:left-0 rounded-3xl overflow-hidden transform transition-all duration-500 hover:scale-105 hover:shadow-2xl z-10">
                <img
                  src={vehicleImg}
                  alt="Our Vehicles"
                  className="w-full h-auto object-cover"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Vision & Mission Section - Responsive */}
        <div className="bg-teal-700 text-white py-12 sm:py-16 transform transition-all duration-500">
          <div className="max-w-4xl mx-auto px-4 text-center space-y-8 sm:space-y-12">
            {/* Vision */}
            <div className="space-y-4 transform transition-all duration-500">
              <h2 className="text-2xl sm:text-3xl font-semibold">Our Vision</h2>
              <p className="text-base sm:text-lg leading-relaxed">
                To be the premier global gateway that inspires, informs, and
                immerses travelers in the captivating beauty and cultural
                richness of Sri Lanka, delivering a seamless and personalized
                experience for every traveler
              </p>
            </div>

            {/* Mission */}
            <div className="space-y-4 transform transition-all duration-500 ">
              <h2 className="text-2xl sm:text-3xl font-semibold">
                Our Mission
              </h2>
              <p className="text-base sm:text-lg leading-relaxed">
                To provide tourists with immersive, customized tours that
                highlight the island's rich cultural legacy, breathtaking
                natural scenery, and genuine local experiences in order to
                inspire and facilitate journeys to Sri Lanka that will never be
                forgotten. In addition to encouraging sustainable practices that
                protect Sri Lanka's natural beauty and help local populations,
                our goal is to introduce tourists to the country's genuine
                essence
              </p>
            </div>

            {/* Contact Button */}
            <Link to="/contact-us" className="mt-8 block">
              <button className="mt-8 bg-white text-teal-700 px-6 py-2 rounded-full font-medium transform transition-all duration-300 hover:scale-110 hover:bg-gray-100 hover:shadow-lg active:scale-95 mx-auto">
                Contact Us
              </button>
            </Link>
          </div>
        </div>

        {/* Divisions Section - Fully Responsive */}
        <div className="py-12 sm:py-16 px-4">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-2xl sm:text-3xl font-semibold text-center mb-12 sm:mb-16">
              Our Divisions
            </h2>

            <div className="relative">
              {/* Vertical Timeline Line - Hidden on Mobile */}
              <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 h-full w-px bg-teal-700" />

              {/* Division Items */}
              <div className="space-y-12 sm:space-y-16">
                {[
                  {
                    title: "Rajarata Foodstuff Trading",
                    content:
                      "As a trusted name in the trading of premium food products, we source and supply the finest quality foodstuffs to meet the diverse requirements of our customers worldwide. Our focus on quality sustainability, and customer service makes us a reliable business partner in the food industry",
                    side: "left",
                  },
                  {
                    title: "Rajarata Plantation",
                    content:
                      "Our plantation division is dedicated to sustainable agriculture, specializing in the cultivation and supply of high-quality agriculture products. We are committed to eco-friendly practices, ensuring that our operations benefit both local communities and the environment",
                    side: "right",
                  },
                  {
                    title: "Rajarata Travel & Tourism",
                    content:
                      "Our travel and tourism division offers unparalleled experiences for travelers seeking adventure, relaxation, and cultural enrichment. From bespoke holiday packages to corporate travel solutions, we provide personalized services to make every journey memorable",
                    side: "left",
                  },
                  {
                    title: "Rajarata Export",
                    content:
                      "Focused on bringing Sri Lanka's finest products to the world, Rajarata Export supplies premium goods to international markets including Dubai, Qatar, and the Maldives. We take pride in promoting the best of Sri Lanka globally, while upholding rigorous standards in sourcing, packaging, and logistics",
                    side: "right",
                  },
                  {
                    title: "Rajarata Transportation",
                    content:
                      "As part of our commitment to showcasing Sri Lanka's natural beauty and providing comprehensive travel and tourism facilities, From organized tours to efficient transportation services, Rajarata's tourism division offers unparalleled experiences for visitors exploring the island.",
                    side: "left",
                  },
                ].map((division, index) => (
                  <div
                    key={index}
                    className={`flex flex-col md:grid md:grid-cols-2 gap-4 relative ${
                      division.side === "left"
                        ? "md:grid-flow-col-dense md:text-right"
                        : "md:grid-flow-col md:text-left"
                    }`}
                  >
                    <div
                      className={`w-full text-center transform transition-all duration-500 hover:scale-105 
                      ${
                        division.side === "left"
                          ? "md:col-start-2 ml-4 md:text-left md:pr-16"
                          : "md:col-start-1  md:text-right md:pl-16"
                      }`}
                      onMouseEnter={() => setHoveredDivision(index)}
                      onMouseLeave={() => setHoveredDivision(null)}
                    >
                      <h3 className="text-xl font-semibold mb-4">
                        {division.title}
                      </h3>
                      <p className="text-gray-600">{division.content}</p>
                    </div>

                    {/* Timeline Point - Visible on Desktop */}
                    <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <div
                        className={`w-4 h-4 rounded-full bg-teal-700 transition-all duration-300 ${
                          hoveredDivision === index
                            ? "scale-150 bg-teal-500"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DirectorsSection />
      <FooterSection />
    </>
  );
};

export default AboutUs;
