import React, { useState, useRef } from "react";
import { Send, User, Mail, MessageSquare, Compass } from "lucide-react";
import emailjs from "@emailjs/browser";
import SecondaryHeader from "../Header";
import FooterSection from "../LoadinPage/Sections-LoadingPage/FooterSection";
import logo from "../../../assets/RajarataLogo.svg";

const ContactForm = () => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    interest: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await emailjs.sendForm(
        "service_dzf5aim",
        "template_0rhtnd6",
        formRef.current,
        "ifO4x0HI6UYsnUtQV"
      );

      setFormData({
        name: "",
        email: "",
        message: "",
        interest: "",
      });
      
      alert("Thank you for your message! We'll get back to you soon.");
    } catch (error) {
      alert("Failed to send message. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SecondaryHeader />
      <div className="min-h-screen bg-gradient-to-br from-[#EAF4F4] via-white to-[#EAF4F4]/30 py-20 px-6 mt-16">
        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-start justify-between gap-20">
          {/* Company Info Section */}
          <div className="w-full lg:w-1/2 space-y-14">
            <div className="flex flex-col items-center lg:items-start">
              <div className="relative mb-10">
                <div className="absolute inset-0 bg-[#F4A261]/20 rounded-full blur-xl"></div>
                <img 
                  src={logo} 
                  alt="Rajarata Logo" 
                  className="relative w-48 h-48 rounded-full shadow-2xl ring-2 ring-[#F4A261] ring-offset-4" 
                />
              </div>
              <div className="space-y-3 text-center lg:text-left">
                <h2 className="text-[#1A4746] text-5xl font-bold tracking-tight">RAJARATA</h2>
                <div className="h-1 w-24 bg-gradient-to-r from-[#F4A261] to-[#215E5C] rounded-full mx-auto lg:mx-0"></div>
                <h3 className="text-[#215E5C] text-2xl font-medium tracking-wide">Tropical Ceylon Tours</h3>
              </div>
            </div>
            
            <div className="space-y-10">
              <p className="text-[#2C3333] text-xl leading-relaxed italic border-l-4 border-[#F4A261] pl-8">
                Experience Sri Lanka's timeless beauty, from ancient ruins to lush
                landscapes. Start your journey with Rajarata Tours.
              </p>
              
              <div className="flex flex-col items-center lg:items-start space-y-4">
                <span className="text-[#215E5C] text-lg font-semibold tracking-wider uppercase">Our Location</span>
                <p className="text-[#2C3333] text-lg font-medium">
                  No 83/N Gamsabawaththa Milithe Kirindiwela
                </p>
              </div>
            </div>
          </div>

          {/* Contact Form Section */}
          <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
            <div className="relative mb-16 text-center lg:text-left">
              <div className="inline-flex items-center justify-center w-16 h-16 bg-gradient-to-br from-[#F4A261] to-[#215E5C] rounded-full mb-8 shadow-lg">
                <MessageSquare className="w-8 h-8 text-white" />
              </div>
              <h2 className="text-[#1A4746] text-4xl font-bold tracking-tight">Get in Touch</h2>
            </div>

            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="space-y-10"
            >
              <div className="relative">
                <label className="absolute -top-6 left-0 text-[#215E5C] text-sm font-medium tracking-wide">FULL NAME</label>
                <div className="absolute left-0 flex items-center h-full pointer-events-none">
                  <User className="w-6 h-6 text-[#215E5C]" />
                </div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full pl-10 pr-4 py-3 bg-transparent border-b-2 border-[#9BBAB9] focus:border-[#F4A261] focus:outline-none transition-all duration-300 text-[#2C3333] text-lg"
                />
              </div>

              <div className="relative">
                <label className="absolute -top-6 left-0 text-[#215E5C] text-sm font-medium tracking-wide">EMAIL ADDRESS</label>
                <div className="absolute left-0 flex items-center h-full pointer-events-none">
                  <Mail className="w-6 h-6 text-[#215E5C]" />
                </div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full pl-10 pr-4 py-3 bg-transparent border-b-2 border-[#9BBAB9] focus:border-[#F4A261] focus:outline-none transition-all duration-300 text-[#2C3333] text-lg"
                />
              </div>

              <div className="relative">
                <label className="absolute -top-6 left-0 text-[#215E5C] text-sm font-medium tracking-wide">TRAVEL INTEREST</label>
                <div className="absolute left-0 flex items-center h-full pointer-events-none">
                  <Compass className="w-6 h-6 text-[#215E5C]" />
                </div>
                <input
                  type="text"
                  name="interest"
                  value={formData.interest}
                  onChange={handleChange}
                  required
                  className="w-full pl-10 pr-4 py-3 bg-transparent border-b-2 border-[#9BBAB9] focus:border-[#F4A261] focus:outline-none transition-all duration-300 text-[#2C3333] text-lg"
                />
              </div>

              <div className="relative">
                <label className="absolute -top-6 left-0 text-[#215E5C] text-sm font-medium tracking-wide">YOUR MESSAGE</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-4 bg-transparent border-b-2 border-[#9BBAB9] focus:border-[#F4A261] focus:outline-none transition-all duration-300 h-32 resize-none text-[#2C3333] text-lg"
                />
              </div>

              <button
                type="submit"
                disabled={loading}
                className="group w-full bg-gradient-to-r from-[#F4A261] to-[#215E5C] text-white font-bold py-4 px-8 rounded-lg transform hover:-translate-y-1 transition-all duration-300 flex items-center justify-center space-x-3 disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none shadow-lg"
              >
                <span className="text-lg tracking-wide">{loading ? "Sending..." : "Send Message"}</span>
                <Send className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default ContactForm;