import React from "react";
import { Facebook, Twitter, Instagram } from "lucide-react";
import logo from "../../../../assets/RajarataLogo.svg";
// Add these imports for your flag icons
import sriLankaFlag from "../../../../assets/sl.png";
import uaeFlag from "../../../../assets/uae.png";
import italyFlag from "../../../../assets/italy.png";

const FooterSection = () => {
  return (
    <footer className="bg-white py-12 px-4 md:px-8 lg:px-16">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <div className="w-24 h-24 relative">
              <img src={logo} alt="Rajarata Logo" className="rounded-full" />
            </div>
            <h2 className="text-teal-700 text-xl font-semibold">RAJARATA</h2>
            <h3 className="text-teal-700 text-lg">Tropical Ceylon Tours</h3>
            <p className="text-gray-600 mt-2">
              Experience Sri Lanka's timeless beauty, from ancient ruins to lush
              landscapes. Start your journey with Rajarata Tours.
            </p>
            <p className="text-gray-600 text-sm">
              No 83/N Gamsabawaththa Milithe Kirindiwela
            </p>
          </div>

          {/* Contact Sri Lanka */}
          <div>
            <h3 className="text-gray-700 font-semibold mb-4 border-b pb-2">
              Contact
            </h3>
            <div className="space-y-4">
              {/* Sri Lanka Section */}
              <div>
                <h4 className="text-gray-600 mb-2 flex items-center gap-2">
                  <img 
                    src={sriLankaFlag} 
                    alt="Sri Lanka Flag" 
                    className="w-6 h-4 object-cover rounded-sm"
                  />
                  <span>Sri Lanka</span>
                </h4>
                <div className="space-y-3">
                  <div>
                    <p className="text-gray-600">Priyantha</p>
                    <p className="text-gray-500 text-sm">+94704282462</p>
                    <a
                      href="mailto:priyantha@rajaratatours.com"
                      className="text-teal-600 text-sm hover:text-teal-700"
                    >
                      priyantha@rajaratatours.com
                    </a>
                  </div>
                  <div>
                    <p className="text-gray-600">Sumith</p>
                    <p className="text-gray-500 text-sm">+94704262462</p>
                    <a
                      href="mailto:priyantha@sumith@rajaratatours.com"
                      className="text-teal-600 text-sm hover:text-teal-700"
                    >
                      sumith@rajaratatours.com
                    </a>
                  </div>
                  <div>
                    <p className="text-gray-600">Nilambara</p>
                    <p className="text-gray-500 text-sm">+94718692399</p>
                    <a
                      href="mailto:nilambara@rajaratatours.com"
                      className="text-teal-600 text-sm hover:text-teal-700"
                    >
                      nilambara@rajaratatours.com
                    </a>
                  </div>
                  <div>
                    <p className="text-gray-600">Chathumi</p>
                    <p className="text-gray-500 text-sm">+94715861141</p>
                    <a
                      href="mailto:chathumi@rajaratatours.com"
                      className="text-teal-600 text-sm hover:text-teal-700"
                    >
                      chathumi@rajaratatours.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* International Contacts */}
          <div>
            <h3 className="text-gray-700 font-semibold mb-4 border-b pb-2">
              International
            </h3>
            <div className="space-y-4">
              <div>
                <h4 className="text-gray-600 mb-2 flex items-center gap-2">
                  <img 
                    src={uaeFlag} 
                    alt="UAE Flag" 
                    className="w-6 h-4 object-cover rounded-sm"
                  />
                  <span>UAE</span>
                </h4>
                <p className="text-gray-600">Amali</p>
                <p className="text-gray-500 text-sm">+97152882355</p>
                <a
                  href="mailto:amali@rajaratatours.com"
                  className="text-teal-600 text-sm hover:text-teal-700"
                >
                  amali@rajaratatours.com
                </a>
              </div>
              <div>
                <h4 className="text-gray-600 mb-2 flex items-center gap-2">
                  <img 
                    src={italyFlag} 
                    alt="Italy Flag" 
                    className="w-6 h-4 object-cover rounded-sm"
                  />
                  <span>Italy</span>
                </h4>
                <p className="text-gray-600">Anura</p>
                <p className="text-gray-500 text-sm">+393282430368</p>
                <a
                  href="mailto:anura@rajaratatours.com"
                  className="text-teal-600 text-sm hover:text-teal-700"
                >
                  anura@rajaratatours.com
                </a>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-gray-700 font-semibold mb-4 border-b pb-2">
              Quick Links
            </h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-teal-600 hover:text-teal-700">
                  Dubai Tour
                </a>
              </li>
              <li>
                <a
                  href="/locations"
                  className="text-teal-600 hover:text-teal-700"
                >
                  Locations
                </a>
              </li>
              <li>
                <a
                  href="/trips-view"
                  className="text-teal-600 hover:text-teal-700"
                >
                  Tour Plans
                </a>
              </li>
              <li>
                <a
                  href="/about-us"
                  className="text-teal-600 hover:text-teal-700"
                >
                  About us
                </a>
              </li>
              <li>
                <a
                  href="https://eta.gov.lk/slvisa/"
                  target="_blank"
                  className="text-teal-600 hover:text-teal-700"
                >
                  E - Visa
                </a>
              </li>
              <li>
                <a
                  href="https://www.airport.lk/"
                  target="_blank"
                  className="text-teal-600 hover:text-teal-700"
                >
                  Flight Details
                </a>
              </li>
              <li>
                <a
                  href="https://www.sltda.gov.lk/en"
                  target="_blank"
                  className="text-teal-600 hover:text-teal-700"
                >
                  Sri Lanaka Toursim
                </a>
              </li>
            </ul>

            {/* Social Media Icons */}
            <div className="mt-6">
              <h3 className="text-gray-700 font-semibold mb-4">Follow Us On</h3>
              <div className="flex space-x-4">
                <a href="https://www.facebook.com/share/3TXZTVaPRJgvEM9x/?mibextid=LQQJ4d" target="_blank" className="text-gray-600 hover:text-teal-700">
                  <Facebook size={24} />
                </a>
                <a href="#" className="text-gray-600 hover:text-teal-700">
                  <Twitter size={24} />
                </a>
                <a href="https://www.instagram.com/rajarata_tours/" target="_blank" className="text-gray-600 hover:text-teal-700">
                  <Instagram size={24} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;