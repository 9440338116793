import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { Check, X, ChevronLeft, ChevronRight } from "lucide-react";
import PlaceHolder from "../../../assets/background.jpg"; // keep placeholder as a fallback image
import SecondaryHeader from "../Header";
import BookingForm from "./BookingForm";
import TripMap from "./TripMap";
import { LoadScript } from "@react-google-maps/api";
import FooterSection from "../LoadinPage/Sections-LoadingPage/FooterSection";

const TripInfo = () => {
  const { tourName } = useParams();
  const [tourData, setTourData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const fetchTourData = async () => {
      try {
        const toursRef = collection(db, "tours");
        const q = query(
          toursRef,
          where("tourName", "==", decodeURIComponent(tourName))
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          setTourData({
            id: querySnapshot.docs[0].id,
            ...data,
            carouselImages: data.galleryImagesUrls || [], // Default to empty array if no images
          });
        }
      } catch (error) {
        console.error("Error fetching tour data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTourData();
  }, [tourName]);

  // Auto-advance carousel
  useEffect(() => {
    const timer = setInterval(() => {
      handleNextImage();
    }, 5000);

    return () => clearInterval(timer);
  }, [currentImageIndex, tourData]);
  const processLocations = (locationsData) => {
    console.log('Locations data:', locationsData);
    
    // If no data, return empty array
    if (!locationsData) return [];
    
    // If it's an array with a string containing locations
    if (Array.isArray(locationsData) && locationsData[0]?.includes('-')) {
      return locationsData[0].split('-').map(location => location.trim());
    }
    
    // If it's already an array of locations
    if (Array.isArray(locationsData)) {
      return locationsData;
    }
    
    // If it's a string with locations
    if (typeof locationsData === 'string' && locationsData.includes('-')) {
      return locationsData.split('-').map(location => location.trim());
    }
    
    // If it's a single location string
    if (typeof locationsData === 'string') {
      return [locationsData];
    }
    
    return [];
  };
  const parseActivityDescription = (description) => {
    // Split the description into points
    const points = description.split('|');
    
    // If multiple points found
    if (points.length > 1) {
      return (
        <div>
          {/* First point as a paragraph */}
          <p className="text-center mb-4">{points[0].trim()}</p>
          
          {/* Centered and aligned list styling */}
          <div className="flex justify-center">
            <ul className="list-disc pl-4 text-center inline-block">
              {points.slice(1).map((point, index) => (
                <li key={index} className="text-left">{point.trim()}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
    
    // If no points, return as paragraph
    return <p className="text-center">{description}</p>;
  };
  // const parseActivityDescription = (description) => {
  //   // Split the description into points
  //   const points = description.split('|');
    
  //   // If multiple points found
  //   if (points.length > 1) {
  //     return (
  //       <div>
  //         {/* First point as a paragraph */}
  //         <p className="mb-4">{points[0].trim()}</p>
          
  //         {/* Aligned list styling */}
  //         <ul className="list-disc pl-4 ">
  //           {points.slice(1).map((point, index) => (
  //             <li key={index} className="flex items-start">
  //               <span className="mr-2 mt-1">•</span>
  //               <span>{point.trim()}</span>
  //             </li>
  //           ))}
  //         </ul>
  //       </div>
  //     );
  //   }
    
  //   // If no points, return as paragraph
  //   return <p>{description}</p>;
  // };
  const handlePreviousImage = () => {
    if (isAnimating || !tourData?.carouselImages?.length) return;
    setIsAnimating(true);
    setCurrentImageIndex((prev) =>
      prev === 0 ? tourData.carouselImages.length - 1 : prev - 1
    );
    setTimeout(() => setIsAnimating(false), 500);
  };

  const handleNextImage = () => {
    if (isAnimating || !tourData?.carouselImages?.length) return;
    setIsAnimating(true);
    setCurrentImageIndex((prev) =>
      prev === tourData.carouselImages.length - 1 ? 0 : prev + 1
    );
    setTimeout(() => setIsAnimating(false), 500);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-teal-500"></div>
      </div>
    );
  }

  if (!tourData) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <h1 className="text-2xl text-red-600">Tour not found</h1>
      </div>
    );
  }

  return (
    <>
      <SecondaryHeader />
      <div className="relative mt-20">
        {/* Hero Section */}
        <div className="relative h-80 w-full">
          <img
            src={tourData.tourBannerImageUrl || PlaceHolder} // Use tourBannerImageUrl or fallback
            alt={tourData.tourName}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50">
            <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
              <h1 className="text-6xl font-bold text-center mb-4">
                {tourData.tourName}
              </h1>
            </div>
          </div>
        </div>

        {/* Content Section with teal background */}
        <div className="bg-teal-800 text-white py-16">
          <div className="max-w-[1400px] mx-auto">
            {/* Top Grid Section */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-16">
              <div className="lg:col-span-2 px-4 lg:pl-8">
                {/* Header content */}
                <div className="max-w-3xl mb-12">
                  <h2 className="text-5xl font-bold mb-6">
                    {tourData.tourName}
                  </h2>
                  <div className="flex items-center gap-4 mb-6">
                    <span className="text-2xl">
                      {tourData.amountOfDays} Days / {tourData.amountOfNights}{" "}
                      Nights
                    </span>
                  </div>
                  <p className="text-xl text-gray-200">
                    {tourData.shortDescription}
                  </p>
                </div>

                {/* Daily Itinerary */}
                 <div className="max-w-3xl space-y-4">
                  {tourData.dailyActivities.map((activity, index) => (
                    <div
                      key={index}
                      className="rounded-3xl border border-white p-4 bg-teal-800"
                    >
                      <h3 className="text-xl font-semibold text-center mb-2">
                        {activity.day}
                      </h3>
                      <div className="text-center text-gray-100">
                        {parseActivityDescription(activity.description)}
                      </div>
                    </div>
                  ))}

                  {/* Extras Section */}
                  <div className="rounded-3xl border border-white p-6 bg-teal-800 mt-16">
                    <h3 className="text-xl font-semibold mb-2 inline-block mr-2">
                      Extras:
                    </h3>
                    <span className="text-gray-100">{tourData.extras}</span>
                  </div>
                </div>
              </div>

              {/* White Box */}
              <div className="lg:col-span-1 px-4 lg:pr-8">
                <div className="bg-white rounded-lg p-8 text-gray-800 h-[500px] lg:mt-[calc(200px)]">
                  <h3 className="text-2xl font-semibold mb-6">Trip Route</h3>
                  {/* Debug output */}
                  {/* <div className="mb-4 text-sm">
                    {tourData?.locations ? (
                      <p>
                        Found locations data:{" "}
                        {JSON.stringify(tourData.locations)}
                      </p>
                    ) : (
                      <p>No locations data available</p>
                    )}
                  </div> */}
                  <div className="h-[400px] w-full">
                    <LoadScript googleMapsApiKey="AIzaSyC81TSWqO98L2BVO-chFVGbzTEdx9hkz7Q">
                      <TripMap
                        locations={processLocations(tourData?.locations)}
                      />
                    </LoadScript>
                  </div>
                </div>
              </div>
            </div>

            {/* Inclusions and Exclusions Section */}
            <div className="px-4 lg:px-8 max-w-8xl mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                {/* Inclusions */}
                <div>
                  <h3 className="text-xl font-semibold mb-6">Inclusions</h3>
                  <ul className="space-y-4">
                    {[
                      "Accommodation in 4-star hotels (BB basis).",
                      "Air-conditioned van with English-speaking driver.",
                      "City tours in Colombo, Kandy, Nuwara Eliya, and Bentota.",
                      "Government taxes (VAT 15%).",
                      "Free Wi-Fi in vehicles (subject to availability).",
                      "Free mobile SIM card upon request.",
                      "Discounts at ODEL, jewelry shops, and Cotton Collection.",
                      "Special honeymoon perks: room decoration and fruit basket.",
                    ].map((item, index) => (
                      <li key={index} className="flex items-start gap-2">
                        <Check className="mt-1 flex-shrink-0" size={20} />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Exclusions */}
                <div>
                  <h3 className="text-xl font-semibold mb-6">Exclusions</h3>
                  <ul className="space-y-4">
                    {[
                      "Early check-in/late check-out fees.",
                      "Entrance fees to attractions.",
                      "Personal expenses (laundry, water, shopping, etc.).",
                      "Any extra costs due to itinerary changes or natural events.",
                    ].map((item, index) => (
                      <li key={index} className="flex items-start gap-2">
                        <X className="mt-1 flex-shrink-0" size={20} />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Image Carousel Section */}
            <div className="mt-16 px-4">
              <h2 className="text-4xl font-bold text-center mb-8">Gallery</h2>
              <div className="relative">
                <div className="relative h-[600px] w-full rounded-2xl overflow-hidden group">
                  <div
                    className="absolute w-full h-full transition-opacity duration-500 ease-in-out"
                    style={{ opacity: isAnimating ? 0 : 1 }}
                  >
                    <img
                      src={
                        tourData.carouselImages?.[currentImageIndex] ||
                        PlaceHolder
                      }
                      alt={`Travel destination ${currentImageIndex + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </div>

                  {/* Navigation arrows */}
                  <button
                    onClick={handlePreviousImage}
                    className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/50 hover:bg-black/75 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  >
                    <ChevronLeft size={24} />
                  </button>
                  <button
                    onClick={handleNextImage}
                    className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/50 hover:bg-black/75 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  >
                    <ChevronRight size={24} />
                  </button>

                  {/* Image indicators */}
                  <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
                    {tourData.carouselImages?.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          if (!isAnimating) {
                            setIsAnimating(true);
                            setCurrentImageIndex(index);
                            setTimeout(() => setIsAnimating(false), 500);
                          }
                        }}
                        className={`w-2 h-2 rounded-full transition-all duration-300 ${
                          currentImageIndex === index
                            ? "bg-white w-4"
                            : "bg-white/50"
                        }`}
                      />
                    ))}
                  </div>
                </div>

                {/* Thumbnail preview */}
                <div className="flex justify-center gap-2 mt-4">
                  {tourData.carouselImages?.map((image, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        if (!isAnimating) {
                          setIsAnimating(true);
                          setCurrentImageIndex(index);
                          setTimeout(() => setIsAnimating(false), 500);
                        }
                      }}
                      className={`relative w-20 h-20 rounded-lg overflow-hidden transition-all duration-300 ${
                        currentImageIndex === index
                          ? "ring-2 ring-white"
                          : "opacity-50"
                      }`}
                    >
                      <img
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <BookingForm />
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default TripInfo;
