import React, { useState } from "react";
import { db } from "../../firebase/firebase";
import { collection, addDoc } from "firebase/firestore";
import { CloudinaryContext } from "cloudinary-react";
import { Cloudinary } from "cloudinary-core";

// Cloudinary configuration
const cloudinary = new Cloudinary({
  cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
  api_secret: process.env.REACT_APP_CLOUDINARY_API_SECRET,
});

// Color palette
const colors = {
  primary: {
    dark: "#1A4746",
    main: "#215E5C",
    light: "#427D7B",
  },
  secondary: {
    main: "#F4A261",
    light: "#F4B583",
  },
  neutral: {
    white: "#EAF4F4",
    light: "#D9E7E6",
    medium: "#9BBAB9",
    dark: "#2C3333",
  },
  success: {
    bg: "#1A4746",
    text: "#EAF4F4",
  },
};

const Tours = () => {
  const [tourName, setTourName] = useState("");
  const [amountOfNights, setAmountOfNights] = useState(1);
  const [amountOfDays, setAmountOfDays] = useState(1);
  const [shortDescription, setShortDescription] = useState("");
  const [dailyActivities, setDailyActivities] = useState([
    { day: "Day 1", description: "" },
  ]);
  const [extras, setExtras] = useState([""]);
  const [locations, setLocations] = useState([""]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [tourBannerImage, setTourBannerImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);

  const handleTourBannerImageUpload = (event) => {
    setTourBannerImage(event.target.files[0]);
  };

  const handleGalleryImagesUpload = (event) => {
    setGalleryImages(Array.from(event.target.files));
  };

  const handleAddDay = () => {
    setDailyActivities([
      ...dailyActivities,
      { day: `Day ${dailyActivities.length + 1}`, description: "" },
    ]);
  };

  const handleRemoveDay = (index) => {
    if (dailyActivities.length > 1) {
      const newActivities = dailyActivities.filter((_, i) => i !== index);
      const updatedActivities = newActivities.map((activity, i) => ({
        ...activity,
        day: `Day ${i + 1}`,
      }));
      setDailyActivities(updatedActivities);
    }
  };

  const handleAddField = (setField, currentField) => {
    setField([...currentField, ""]);
  };

  const handleRemoveField = (index, setField, currentField) => {
    if (currentField.length > 1) {
      const newArray = currentField.filter((_, i) => i !== index);
      setField(newArray);
    }
  };

  const handleChangeField = (index, value, currentField, setField) => {
    const newArray = [...currentField];
    newArray[index] = value;
    setField(newArray);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const uploadToCloudinary = async (file) => {
      if (!file) return null; // Return null if no file is provided
  
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "unsigned_upload"); // Ensure this matches your Cloudinary upload preset
  
      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
  
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Cloudinary upload failed: ${errorText}`);
        }
  
        const data = await response.json();
        return data.secure_url;
      } catch (error) {
        console.error("Cloudinary upload error:", error);
        return null;
      }
    };
  
    try {
      // Banner image upload with null check
      const tourBannerImageUrl = tourBannerImage 
        ? await uploadToCloudinary(tourBannerImage) 
        : null;
  
      // Gallery image upload with null check
      const galleryImagesUrls = galleryImages.length > 0
        ? await Promise.all(galleryImages.map((image) => uploadToCloudinary(image)))
        : [];
  
      // Ensure all optional fields have default values
      const tourData = {
        tourName: tourName || '',
        amountOfNights: amountOfNights || 1,
        amountOfDays: amountOfDays || 1,
        shortDescription: shortDescription || '',
        dailyActivities: dailyActivities.map(activity => ({
          day: activity.day || '',
          description: activity.description || ''
        })),
        extras: extras.filter(extra => extra.trim() !== ''),
        locations: locations.filter(location => location.trim() !== ''),
        tourBannerImageUrl: tourBannerImageUrl,
        galleryImagesUrls: galleryImagesUrls
      };
  
      // Save the tour data to Firestore
      await addDoc(collection(db, "tours"), tourData);
  
      // Reset form state
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
      resetForm();
  
    } catch (error) {
      console.error("Error adding document: ", error);
      // Optionally, add user-facing error handling
      alert("Failed to add tour. Please check your inputs and try again.");
    }
  };
  
  // Helper function to reset form
  const resetForm = () => {
    setTourBannerImage(null);
    setGalleryImages([]);
    setTourName("");
    setAmountOfNights(1);
    setAmountOfDays(1);
    setShortDescription("");
    setDailyActivities([{ day: "Day 1", description: "" }]);
    setExtras([""]);
    setLocations([""]);
  };
  return (
    <CloudinaryContext cloudName="your-cloudinary-cloud-name">
      <div
        className="max-w-4xl mx-auto rounded-lg shadow-lg p-6"
        style={{
          backgroundColor: colors.primary.dark,
          color: colors.neutral.white,
        }}
      >
        <h2
          className="text-2xl font-bold mb-6"
          style={{ color: colors.neutral.white }}
        >
          Tour Management
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          {showSuccess && (
            <div
              className="px-4 py-3 rounded relative mb-4"
              style={{
                backgroundColor: colors.success.bg,
                color: colors.success.text,
                borderColor: colors.primary.light,
              }}
            >
              Tour successfully added!
            </div>
          )}

          <div className="grid gap-4 md:grid-cols-2">
            <div className="space-y-2">
              <label
                className="block text-sm font-medium"
                style={{ color: colors.neutral.white }}
              >
                Tour Name
              </label>
              <input
                value={tourName}
                onChange={(e) => setTourName(e.target.value)}
                required
                className="w-full px-3 py-2 rounded-md focus:outline-none transition-colors"
                style={{
                  backgroundColor: colors.primary.main,
                  color: colors.neutral.white,
                  borderColor: colors.neutral.light,
                  border: `1px solid ${colors.neutral.light}`,
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label
                  className="block text-sm font-medium"
                  style={{ color: colors.neutral.white }}
                >
                  Nights
                </label>
                <input
                  type="number"
                  value={amountOfNights}
                  onChange={(e) => setAmountOfNights(Number(e.target.value))}
                  min="1"
                  required
                  className="w-full px-3 py-2 rounded-md focus:outline-none transition-colors"
                  style={{
                    backgroundColor: colors.primary.main,
                    color: colors.neutral.white,
                    borderColor: colors.neutral.light,
                    border: `1px solid ${colors.neutral.light}`,
                  }}
                />
              </div>
              <div className="space-y-2">
                <label
                  className="block text-sm font-medium"
                  style={{ color: colors.neutral.white }}
                >
                  Days
                </label>
                <input
                  type="number"
                  value={amountOfDays}
                  onChange={(e) => setAmountOfDays(Number(e.target.value))}
                  min="1"
                  required
                  className="w-full px-3 py-2 rounded-md focus:outline-none transition-colors"
                  style={{
                    backgroundColor: colors.primary.main,
                    color: colors.neutral.white,
                    borderColor: colors.neutral.light,
                    border: `1px solid ${colors.neutral.light}`,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <label
              className="block text-sm font-medium"
              style={{ color: colors.neutral.white }}
            >
              Description
            </label>
            <textarea
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
              required
              className="w-full px-3 py-2 rounded-md focus:outline-none min-h-[100px] transition-colors"
              style={{
                backgroundColor: colors.primary.main,
                color: colors.neutral.white,
                borderColor: colors.neutral.light,
                border: `1px solid ${colors.neutral.light}`,
              }}
            />
          </div>

          <div className="space-y-4">
            {/* Activities Section */}
            <div
              className="border rounded-md overflow-hidden"
              style={{ borderColor: colors.neutral.light }}
            >
              <button
                type="button"
                onClick={() =>
                  setActiveSection(
                    activeSection === "activities" ? "" : "activities"
                  )
                }
                className="w-full px-4 py-2 text-left flex justify-between items-center transition-colors"
                style={{
                  backgroundColor:
                    activeSection === "activities"
                      ? colors.primary.light
                      : colors.primary.main,
                  color: colors.neutral.white,
                }}
              >
                <span className="font-medium">Daily Activities</span>
                <span>{activeSection === "activities" ? "−" : "+"}</span>
              </button>

              {activeSection === "activities" && (
                <div
                  className="p-4 space-y-4"
                  style={{ backgroundColor: colors.primary.dark }}
                >
                  {dailyActivities.map((activity, index) => (
                    <div key={index} className="space-y-2">
                      <div className="flex items-center justify-between">
                        <h4
                          className="font-medium"
                          style={{ color: colors.neutral.white }}
                        >
                          {activity.day}
                        </h4>
                        {dailyActivities.length > 1 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveDay(index)}
                            className="text-sm py-1 px-2 rounded transition-colors"
                            style={{
                              backgroundColor: colors.primary.main,
                              color: colors.secondary.main,
                            }}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                      <textarea
                        value={activity.description}
                        onChange={(e) => {
                          const newActivities = [...dailyActivities];
                          newActivities[index].description = e.target.value;
                          setDailyActivities(newActivities);
                        }}
                        placeholder="Describe the activities for this day..."
                        required
                        className="w-full px-3 py-2 rounded-md focus:outline-none transition-colors"
                        style={{
                          backgroundColor: colors.primary.main,
                          color: colors.neutral.white,
                          borderColor: colors.neutral.light,
                          border: `1px solid ${colors.neutral.light}`,
                        }}
                      />
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddDay}
                    className="w-full px-4 py-2 rounded-md transition-colors"
                    style={{
                      backgroundColor: colors.secondary.main,
                      color: colors.primary.dark,
                      ":hover": {
                        backgroundColor: colors.secondary.light,
                      },
                    }}
                  >
                    + Add Another Day
                  </button>
                </div>
              )}
            </div>
            <div className="space-y-2">
              <label
                className="block text-sm font-medium"
                style={{ color: colors.neutral.white }}
              >
                Tour Banner Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleTourBannerImageUpload}
                className="w-full px-3 py-2 rounded-md focus:outline-none transition-colors"
                style={{
                  backgroundColor: colors.primary.main,
                  color: colors.neutral.white,
                  borderColor: colors.neutral.light,
                  border: `1px solid ${colors.neutral.light}`,
                }}
              />
            </div>

            <div className="space-y-2">
              <label
                className="block text-sm font-medium"
                style={{ color: colors.neutral.white }}
              >
                Gallery Images
              </label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleGalleryImagesUpload}
                className="w-full px-3 py-2 rounded-md focus:outline-none transition-colors"
                style={{
                  backgroundColor: colors.primary.main,
                  color: colors.neutral.white,
                  borderColor: colors.neutral.light,
                  border: `1px solid ${colors.neutral.light}`,
                }}
              />
            </div>

            {/* Tour Details Section */}
            <div
              className="border rounded-md overflow-hidden"
              style={{ borderColor: colors.neutral.light }}
            >
              <button
                type="button"
                onClick={() =>
                  setActiveSection(activeSection === "details" ? "" : "details")
                }
                className="w-full px-4 py-2 text-left flex justify-between items-center transition-colors"
                style={{
                  backgroundColor:
                    activeSection === "details"
                      ? colors.primary.light
                      : colors.primary.main,
                  color: colors.neutral.white,
                }}
              >
                <span className="font-medium">Tour Details</span>
                <span>{activeSection === "details" ? "−" : "+"}</span>
              </button>

              {activeSection === "details" && (
                <div
                  className="p-4 space-y-6"
                  style={{ backgroundColor: colors.primary.dark }}
                >
                  {/* Dynamic fields */}
                  {["Locations", "Extras"].map((section) => (
                    <div key={section} className="space-y-2">
                      <div className="flex items-center justify-between">
                        <h3
                          className="text-lg font-semibold"
                          style={{ color: colors.neutral.white }}
                        >
                          {section}
                        </h3>
                        <button
                          type="button"
                          onClick={() =>
                            handleAddField(
                              section === "Locations"
                                ? setLocations
                                : setExtras,
                              section === "Locations" ? locations : extras
                            )
                          }
                          className="px-3 py-1 text-sm rounded-md transition-colors"
                          style={{
                            backgroundColor: colors.secondary.main,
                            color: colors.primary.dark,
                          }}
                        >
                          + Add {section}
                        </button>
                      </div>
                      {(section === "Locations" ? locations : extras).map(
                        (item, index) => (
                          <div key={index} className="flex items-center gap-2">
                            <input
                              value={item}
                              onChange={(e) =>
                                handleChangeField(
                                  index,
                                  e.target.value,
                                  section === "Locations" ? locations : extras,
                                  section === "Locations"
                                    ? setLocations
                                    : setExtras
                                )
                              }
                              placeholder={`Enter ${section.toLowerCase()}...`}
                              className="flex-1 px-3 py-2 rounded-md focus:outline-none transition-colors"
                              style={{
                                backgroundColor: colors.primary.main,
                                color: colors.neutral.white,
                                borderColor: colors.neutral.light,
                                border: `1px solid ${colors.neutral.light}`,
                              }}
                            />
                            {(section === "Locations" ? locations : extras)
                              .length > 1 && (
                              <button
                                type="button"
                                onClick={() =>
                                  handleRemoveField(
                                    index,
                                    section === "Locations"
                                      ? setLocations
                                      : setExtras,
                                    section === "Locations" ? locations : extras
                                  )
                                }
                                className="p-2 rounded-full transition-colors"
                                style={{ color: colors.secondary.main }}
                              >
                                ×
                              </button>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 rounded-md transition-colors"
            style={{
              backgroundColor: colors.secondary.main,
              color: colors.primary.dark,
              ":hover": {
                backgroundColor: colors.secondary.light,
              },
            }}
          >
            Save Tour
          </button>
        </form>
      </div>
    </CloudinaryContext>
  );
};

export default Tours;
