import React, { useEffect, useState } from 'react';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';

const TripMap = ({ locations = [] }) => {
  const [directions, setDirections] = useState(null);
  const [center, setCenter] = useState({ lat: 7.8731, lng: 80.7718 }); // Sri Lanka center
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchDirections = async () => {
      console.log('Processing locations in TripMap:', locations);
      
      if (!locations || locations.length < 2) {
        console.log('Need at least 2 locations for directions');
        return;
      }
      
      if (!window.google || !window.google.maps) {
        console.error('Google Maps not loaded');
        return;
      }
      
      const directionsService = new window.google.maps.DirectionsService();
      const geocoder = new window.google.maps.Geocoder();
      
      try {
        // First, geocode all locations
        const coordinates = await Promise.all(
          locations.map(async (location) => {
            if (!location) return null;
            
            return new Promise((resolve) => {
              geocoder.geocode({ address: location }, (results, status) => {
                if (status === 'OK' && results && results[0]) {
                  resolve({
                    location: results[0].geometry.location,
                    name: location
                  });
                } else {
                  console.error('Geocoding failed for:', location, 'Status:', status);
                  resolve(null);
                }
              });
            });
          })
        );
        
        const validCoordinates = coordinates.filter(coord => coord !== null);
        
        if (validCoordinates.length < 2) {
          setError('Need at least 2 valid locations to show route');
          return;
        }

        // Create waypoints from all locations except first and last
        const waypoints = validCoordinates.slice(1, -1).map(coord => ({
          location: coord.location,
          stopover: true
        }));

        const directionsRequest = {
          origin: validCoordinates[0].location,
          destination: validCoordinates[validCoordinates.length - 1].location,
          waypoints: waypoints,
          optimizeWaypoints: false,
          travelMode: window.google.maps.TravelMode.DRIVING
        };

        directionsService.route(directionsRequest, (result, status) => {
          if (status === 'OK') {
            setDirections(result);
            // Calculate bounds from directions
            const bounds = new window.google.maps.LatLngBounds();
            result.routes[0].legs.forEach(leg => {
              leg.steps.forEach(step => {
                step.path.forEach(point => {
                  bounds.extend(point);
                });
              });
            });
            setCenter(bounds.getCenter().toJSON());
          } else {
            setError(`Error fetching directions: ${status}`);
            console.error('Directions request failed:', status);
          }
        });
      } catch (error) {
        console.error('Error processing locations:', error);
        setError('Failed to process locations');
      }
    };
    
    fetchDirections();
  }, [locations]);
  
  const mapContainerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '0.5rem'
  };
  
  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    styles: [
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#444444' }]
      }
    ]
  };
  
  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={8}
        options={mapOptions}
      >
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              suppressMarkers: false,
              polylineOptions: {
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 3,
              }
            }}
          />
        )}
      </GoogleMap>
      {error && (
        <div className="text-red-500 mt-2 text-sm">
          {error}
        </div>
      )}
    </>
  );
};

export default TripMap;