import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import {
  Calendar,
  Plus,
  Minus,
  MapPin,
  Users,
  Mail,
  Phone,
  Home,
  MessageSquare,
} from "lucide-react";
import { useSearchParams } from "react-router-dom";
import headerBackground from "../../../assets/background.jpg";
import Header from "../Header";
import FooterSection from "../LoadinPage/Sections-LoadingPage/FooterSection";

const LocationForm = () => {
  const form = useRef();
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState({});

  // Get locations from URL parameters
  const locationsFromUrl = searchParams.get("locations")?.split(",") || [];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    arrivalDate: "",
    departureDate: "",
    pickupPoint: "",
    dropoffPoint: "",
    entryFees: "",
    rooms: "",
    adults: 1,
    childrenUnder11: 0,
    childrenUnder5: 0,
    comments: "",
    locations: locationsFromUrl.join(", "),
  });
  const colors = {
    primary: {
      dark: "#1A4746",
      main: "#215E5C",
      light: "#427D7B",
    },
    secondary: {
      main: "#F4A261",
      light: "#F4B583",
    },
    neutral: {
      white: "#EAF4F4",
      light: "#D9E7E6",
      medium: "#9BBAB9",
      dark: "#2C3333",
    },
    success: {
      bg: "#1A4746",
      text: "#EAF4F4",
    },
  };

  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  // Update locations if URL parameters change
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      locations: locationsFromUrl.join(", "),
    }));
  }, [searchParams]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email))
      newErrors.email = "Valid email is required";
    const phoneRegex = /^\d{10}$/;
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone))
      newErrors.phone = "Valid 10-digit phone number is required";
    if (!formData.address.trim()) newErrors.address = "Address is required";
    if (!formData.arrivalDate)
      newErrors.arrivalDate = "Arrival date is required";
    if (!formData.departureDate)
      newErrors.departureDate = "Departure date is required";
    if (
      formData.arrivalDate &&
      formData.departureDate &&
      new Date(formData.arrivalDate) > new Date(formData.departureDate)
    ) {
      newErrors.departureDate = "Departure date must be after arrival date";
    }
    if (!formData.pickupPoint)
      newErrors.pickupPoint = "Please select pickup point";
    if (!formData.dropoffPoint.trim())
      newErrors.dropoffPoint = "Drop-off point is required";
    if (!formData.entryFees)
      newErrors.entryFees = "Please select entry fees option";
    if (!formData.rooms) newErrors.rooms = "Please select rooms option";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleNumberChange = (field, increment) => {
    setFormData((prev) => ({
      ...prev,
      [field]: Math.max(0, prev[field] + increment),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSubmitStatus({
        type: "error",
        message: "Please fix the errors in the form",
      });
      return;
    }

    setLoading(true);
    try {
      await emailjs.sendForm(
        "service_dzf5aim",
        "template_0rhtnd6",
        form.current,
        "ifO4x0HI6UYsnUtQV"
      );
      setSubmitStatus({
        type: "success",
        message: "Booking request sent successfully!",
      });
      setFormData({
        name: "",
        email: "",
        phone: "",
        address: "",
        arrivalDate: "",
        departureDate: "",
        pickupPoint: "",
        dropoffPoint: "",
        entryFees: "",
        rooms: "",
        adults: 1,
        childrenUnder11: 0,
        childrenUnder5: 0,
        comments: "",
        locations: locationsFromUrl.join(", "),
      });
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "Failed to send booking request. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const InputWithIcon = ({ icon: Icon, ...props }) => (
    <div className="relative">
      <Icon
        className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
        size={18}
      />
      <input
        {...props}
        className={`w-full px-4 py-3 pl-10 rounded-lg bg-white border ${
          errors[props.name] ? "border-red-300" : "border-gray-200"
        } text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200`}
      />
    </div>
  );

  return (
    <>
      <Header />
      <div className="min-h-screen mt-16  bg-gradient-to-br from-[#EAF4F4] to-[#427D7B]">
        {/* Header Section */}
        <header
          className="bg-cover bg-center h-80 flex items-center justify-center"
          style={{
            backgroundImage: `url(${headerBackground})`,
          }}
        >
          <h1 className="text-4xl font-bold text-[#EAF4F4]">
            Book Your Adventure
          </h1>
        </header>

        {/* Form Section */}
        <div className="max-w-6xl mx-auto mt-20 px-8 md:px-0 ">
          <form
            ref={form}
            onSubmit={handleSubmit}
            className="bg-[#EAF4F4] rounded-2xl shadow-xl overflow-hidden p-8"
          >
            {locationsFromUrl.length > 0 && (
              <div className="mb-8 p-4 bg-[#D9E7E6] rounded-lg">
                <h3 className="text-sm font-medium text-[#215E5C] mb-2">
                  Selected Destinations
                </h3>
                <div className="flex flex-wrap gap-2">
                  {locationsFromUrl.map((location, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-[#9BBAB9] text-[#EAF4F4]"
                    >
                      <MapPin size={14} className="mr-1" />
                      {location}
                    </span>
                  ))}
                </div>
                <input
                  type="hidden"
                  name="locations"
                  value={formData.locations}
                />
              </div>
            )}

            {/* Personal Information */}
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-[#215E5C] mb-2">
                    Full Name
                  </label>
                  <InputWithIcon
                    icon={Users}
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="John Doe"
                  />
                  {errors.name && (
                    <p className="mt-1 text-sm text-red-500">{errors.name}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-[#215E5C] mb-2">
                    Email Address
                  </label>
                  <InputWithIcon
                    icon={Mail}
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="john@example.com"
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-500">{errors.email}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-[#215E5C] mb-2">
                    Phone Number
                  </label>
                  <InputWithIcon
                    icon={Phone}
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="1234567890"
                  />
                  {errors.phone && (
                    <p className="mt-1 text-sm text-red-500">{errors.phone}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-[#215E5C] mb-2">
                    Address
                  </label>
                  <InputWithIcon
                    icon={Home}
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Your address"
                  />
                  {errors.address && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.address}
                    </p>
                  )}
                </div>
              </div>

              {/* Trip Details */}
              <div className="border-t pt-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  Trip Details
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Arrival Date
                    </label>
                    <InputWithIcon
                      icon={Calendar}
                      type="date"
                      name="arrivalDate"
                      value={formData.arrivalDate}
                      onChange={handleChange}
                    />
                    {errors.arrivalDate && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.arrivalDate}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Departure Date
                    </label>
                    <InputWithIcon
                      icon={Calendar}
                      type="date"
                      name="departureDate"
                      value={formData.departureDate}
                      onChange={handleChange}
                    />
                    {errors.departureDate && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.departureDate}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Pickup Point
                    </label>
                    <select
                      name="pickupPoint"
                      value={formData.pickupPoint}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg bg-white border border-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
                    >
                      <option value="">Select Pickup Point</option>
                      <option value="hotel">Hotel</option>
                      <option value="airport">Airport</option>
                    </select>
                    {errors.pickupPoint && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.pickupPoint}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Drop-off Point
                    </label>
                    <InputWithIcon
                      icon={MapPin}
                      type="text"
                      name="dropoffPoint"
                      value={formData.dropoffPoint}
                      onChange={handleChange}
                      placeholder="Drop-off location"
                    />
                    {errors.dropoffPoint && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.dropoffPoint}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Travelers Section */}
              <div className="border-t pt-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  Travelers
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {[
                    { label: "Adults", field: "adults", min: 1 },
                    {
                      label: "Children (Under 11)",
                      field: "childrenUnder11",
                      min: 0,
                    },
                    {
                      label: "Children (Under 5)",
                      field: "childrenUnder5",
                      min: 0,
                    },
                  ].map((item) => (
                    <div key={item.field} className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700">
                          {item.label}
                        </span>
                        <div className="flex items-center space-x-3">
                          <button
                            type="button"
                            onClick={() => handleNumberChange(item.field, -1)}
                            disabled={formData[item.field] <= item.min}
                            className="p-1 rounded-full hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                          >
                            <Minus size={16} className="text-gray-600" />
                          </button>
                          <span className="w-8 text-center text-gray-900 font-medium">
                            {formData[item.field]}
                          </span>
                          <button
                            type="button"
                            onClick={() => handleNumberChange(item.field, 1)}
                            className="p-1 rounded-full hover:bg-gray-200 transition-colors duration-200"
                          >
                            <Plus size={16} className="text-gray-600" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Additional Comments */}
              <div className="border-t pt-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Additional Comments
                </label>
                <div className="relative">
                  <MessageSquare
                    className="absolute left-3 top-3 text-gray-400"
                    size={18}
                  />
                  <textarea
                    name="comments"
                    value={formData.comments}
                    onChange={handleChange}
                    placeholder="Share any special requirements or questions..."
                    rows={4}
                    className="w-full px-4 py-3 pl-10 rounded-lg bg-white border border-gray-200 text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200 resize-none"
                  />
                </div>
              </div>

              {/* Submit Button and Status Messages */}
              <div className="border-t pt-6">
                {submitStatus && (
                  <div
                    className={`p-4 rounded-lg mb-4 ${
                      submitStatus.type === "success"
                        ? "bg-green-50 text-green-800 border border-green-200"
                        : "bg-red-50 text-red-800 border border-red-200"
                    }`}
                  >
                    {submitStatus.message}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full py-3 px-6 rounded-lg bg-gradient-to-r from-[#215E5C] to-[#427D7B] text-[#EAF4F4] font-semibold hover:from-[#1A4746] hover:to-[#427D7B] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <div className="flex items-center justify-center gap-2">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#EAF4F4]"></div>
                      <span>Processing your booking...</span>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center gap-2">
                      <span>Complete Booking</span>
                    </div>
                  )}
                </button>
                {/* Booking Summary */}
                <div className="mt-6 bg-[#D9E7E6] rounded-lg p-4">
                  <h4 className="text-sm font-medium text-[#215E5C] mb-2">
                    Booking Summary
                  </h4>
                  <div className="space-y-2 text-sm text-gray-600">
                    <div className="flex justify-between">
                      <span>Total Travelers</span>
                      <span className="font-medium text-gray-900">
                        {formData.adults +
                          formData.childrenUnder11 +
                          formData.childrenUnder5}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span>Duration</span>
                      <span className="font-medium text-gray-900">
                        {formData.arrivalDate && formData.departureDate
                          ? `${Math.ceil(
                              (new Date(formData.departureDate) -
                                new Date(formData.arrivalDate)) /
                                (1000 * 60 * 60 * 24)
                            )} days`
                          : "Not specified"}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span>Accommodation</span>
                      <span className="font-medium text-gray-900">
                        {formData.rooms === "with"
                          ? "Included"
                          : "Not included"}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span>Entry Fees</span>
                      <span className="font-medium text-gray-900">
                        {formData.entryFees === "with"
                          ? "Included"
                          : "Not included"}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Additional Options */}
                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Entry Fees Option
                    </label>
                    <select
                      name="entryFees"
                      value={formData.entryFees}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg bg-white border border-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
                    >
                      <option value="">Select Entry Fees Option</option>
                      <option value="without">Without Entry</option>
                      <option value="with">With Entry</option>
                    </select>
                    {errors.entryFees && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.entryFees}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Accommodation
                    </label>
                    <select
                      name="rooms"
                      value={formData.rooms}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg bg-white border border-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
                    >
                      <option value="">Select Rooms Option</option>
                      <option value="with">With Rooms</option>
                      <option value="without">Without Rooms</option>
                    </select>
                    {errors.rooms && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.rooms}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mt-6 text-center text-sm text-[#9BBAB9]">
                  By clicking "Complete Booking", you agree to our{" "}
                  <a href="#" className="text-[#215E5C] hover:text-[#427D7B]">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="#" className="text-[#215E5C] hover:text-[#427D7B]">
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-8"> <FooterSection /></div>
      </div>

     
    </>
  );
};

export default LocationForm;
