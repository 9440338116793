import React from 'react';
import Director1Img from '../../../assets/Director1.jpg';
import Director2Img from '../../../assets/Director2.jpg';
import Director3Img from '../../../assets/Director3.jpg';

const DirectorsSection = () => {
  const directors = [
    {
      name: "RRP Rathnayake",
      position: "Major (Rtd) Sri Lanka Army",
      image: Director1Img,
      imagePosition: "object-[55%_35%]"
    },
    {
      name: "RRD Sumali Rathnayake",
      position: "B.Ed in English",
      image: Director2Img,
      imagePosition: "object-[50%_10%]"
    
    },
    {
      name: "RRC Rathnayake",
      position: "Undergraduate",
      image: Director3Img
    }
  ];

  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 text-teal-800">
          Our Directors
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {directors.map((director, index) => (
            <div 
              key={index} 
              className="flex flex-col items-center"
            >
              <div className="relative group">
                <div className="w-56 h-56 rounded-full overflow-hidden border-4 border-teal-600 shadow-lg">
                  <img 
                    src={director.image} 
                    alt={director.name} 
                    className={`w-full h-full object-cover ${director.imagePosition} transform group-hover:scale-110 transition-transform duration-300`}
                  />
                </div>
              </div>
              <div className="text-center mt-6">
                <h3 className="text-xl font-semibold text-teal-900 mb-2">
                  {director.name}
                </h3>
                <p className="text-teal-700 italic">
                  {director.position}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DirectorsSection;