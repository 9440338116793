import React, { useState, useEffect } from "react";
import { db } from "../../firebase/firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const TourManagement = () => {
  const [tours, setTours] = useState([]);
  const [editingTour, setEditingTour] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newTourBannerImage, setNewTourBannerImage] = useState(null);
  const [newGalleryImages, setNewGalleryImages] = useState([]);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const tourCollection = await getDocs(collection(db, "tours"));
        const toursData = tourCollection.docs.map((doc) => {
          const data = doc.data() || {};
          return {
            id: doc.id,
            tourName: data.tourName || "Untitled Tour",
            tourBannerImageUrl: data.tourBannerImageUrl || "",
            galleryImagesUrls: data.galleryImagesUrls || [],
            dailyActivities: data.dailyActivities || [],
            extras: data.extras || [],
            locations: data.locations || [],
            shortDescription: data.shortDescription || "",
            amountOfNights: data.amountOfNights || 0,
            amountOfDays: data.amountOfDays || 0,
          };
        });
        setTours(toursData);
      } catch (error) {
        console.error("Error fetching tours:", error);
        alert("Failed to load tours. Please try again later.");
      }
    };

    fetchTours();
  }, []);


  const handleEdit = (tour) => {
    setEditingTour({
      ...tour,
      tourBannerImageUrl: tour.tourBannerImageUrl || "",
      galleryImagesUrls: tour.galleryImagesUrls || [],
      dailyActivities: tour.dailyActivities || [],
      extras: tour.extras || [],
      locations: tour.locations || [],
    });
    setNewTourBannerImage(null);
    setNewGalleryImages([]);
    setShowEditModal(true);
  };
  const uploadToCloudinary = async (file) => {
    if (!file) return null;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "unsigned_upload");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Upload failed: ${await response.text()}`);
      }

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Upload error:", error);
      return null;
    }
  };

  const handleUpdateTour = async () => {
    try {
      // Upload new banner image if provided
      let updatedTourBannerImageUrl = editingTour.tourBannerImageUrl;
      if (newTourBannerImage) {
        const newBannerUrl = await uploadToCloudinary(newTourBannerImage);
        if (newBannerUrl) {
          updatedTourBannerImageUrl = newBannerUrl;
        }
      }

      // Upload new gallery images if provided
      let updatedGalleryImagesUrls = [...(editingTour.galleryImagesUrls || [])];
      if (newGalleryImages.length > 0) {
        const newUrls = await Promise.all(
          newGalleryImages.map((image) => uploadToCloudinary(image))
        );
        updatedGalleryImagesUrls = [
          ...updatedGalleryImagesUrls,
          ...newUrls.filter(Boolean),
        ];
      }

      // Update the tour document in Firestore
      const tourDoc = doc(db, "tours", editingTour.id);
      const updatedTour = {
        ...editingTour,
        tourBannerImageUrl: updatedTourBannerImageUrl,
        galleryImagesUrls: updatedGalleryImagesUrls,
      };

      await updateDoc(tourDoc, updatedTour);

      // Update local state
      setTours((prevTours) =>
        prevTours.map((tour) =>
          tour.id === editingTour.id ? updatedTour : tour
        )
      );

      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating tour:", error);
      alert("Failed to update tour. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this tour?")) {
      try {
        await deleteDoc(doc(db, "tours", id));
        setTours((prevTours) => prevTours.filter((tour) => tour.id !== id));
      } catch (error) {
        console.error("Error deleting tour:", error);
        alert("Failed to delete tour. Please try again.");
      }
    }
  };
  const handleRemoveGalleryImage = (index) => {
    if (editingTour?.galleryImagesUrls) {
      const updatedGalleryUrls = [...editingTour.galleryImagesUrls];
      updatedGalleryUrls.splice(index, 1);
      setEditingTour({
        ...editingTour,
        galleryImagesUrls: updatedGalleryUrls,
      });
    }
  };

  const handleAddField = (fieldName) => {
    if (fieldName === "dailyActivities") {
      setEditingTour({
        ...editingTour,
        dailyActivities: [
          ...editingTour.dailyActivities,
          { day: "", description: "" },
        ],
      });
    } else {
      setEditingTour({
        ...editingTour,
        [fieldName]: [...editingTour[fieldName], ""],
      });
    }
  };

  const handleRemoveField = (fieldName, index) => {
    setEditingTour({
      ...editingTour,
      [fieldName]: editingTour[fieldName].filter((_, idx) => idx !== index),
    });
  };

  return (
    <div className="min-h-screen bg-[#EAF4F4] p-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-[#1A4746] mb-8">
          Tour Management
        </h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {tours.length > 0 ? (
            tours.map((tour) => (
              <div
                key={tour.id}
                className="bg-white rounded-xl shadow-lg overflow-hidden transition-all hover:shadow-xl border border-[#D9E7E6]"
              >
                <div className="bg-[#215E5C] p-4">
                  <h3 className="text-2xl font-bold text-[#EAF4F4]">
                    {tour.tourName}
                  </h3>
                </div>
                <div className="p-6">
                  <p>{tour.shortDescription}</p>
                  {/* Additional fields rendered as needed */}
                  <button
                    onClick={() => handleEdit(tour)}
                    className="bg-[#215E5C] text-white p-2 rounded mt-4"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(tour.id)}
                    className="bg-red-500 text-white p-2 rounded mt-4 ml-2"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No tours available.</p>
          )}
        </div>
        {/* Edit Modal */}
        {showEditModal && editingTour && (
          <div className="fixed inset-0 bg-[#2C3333] bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto">
              <div className="sticky top-0 bg-[#215E5C] p-6">
                <h2 className="text-2xl font-bold text-[#EAF4F4]">
                  Edit Tour Details
                </h2>
              </div>
              <div className="p-6 space-y-6">
                {/* Banner Image Section */}
                <div>
                <label className="block text-sm font-medium text-[#2C3333] mb-2">
                  Tour Name
                </label>
                <input
                  type="text"
                  value={editingTour.tourName}
                  onChange={(e) =>
                    setEditingTour({ ...editingTour, tourName: e.target.value })
                  }
                  className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                />
              </div>
                <div>
                  <h4 className="font-medium text-[#215E5C] mb-2">
                    Banner Image
                  </h4>
                  {editingTour.tourBannerImageUrl && (
                    <div className="mb-2">
                      <img
                        src={editingTour.tourBannerImageUrl}
                        alt="Current banner"
                        className="w-full h-40 object-cover rounded-lg"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setNewTourBannerImage(e.target.files[0])}
                    className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                  />
                </div>
                <div>
                  <h4>Gallery Images</h4>
                  <div className="grid grid-cols-3 gap-4">
                    {editingTour.galleryImagesUrls.map((url, idx) => (
                      <div key={idx} className="relative">
                        <img
                          src={url}
                          alt={`Gallery ${idx + 1}`}
                          className="w-full h-32 object-cover rounded-lg"
                        />
                        <button
                          onClick={() => handleRemoveGalleryImage(idx)}
                          className="absolute top-2 right-2 bg-red-500 text-white w-8 h-8 flex items-center justify-center rounded-full"
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) =>
                      setNewGalleryImages(Array.from(e.target.files))
                    }
                    className="mt-4"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-[#2C3333] mb-1">
                    Short Description (shortDescription)
                  </label>
                  <textarea
                    value={editingTour.shortDescription}
                    onChange={(e) =>
                      setEditingTour({
                        ...editingTour,
                        shortDescription: e.target.value,
                      })
                    }
                    className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                    rows="3"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-[#2C3333] mb-1">
                      Nights (amountOfNights)
                    </label>
                    <input
                      type="number"
                      value={editingTour.amountOfNights}
                      onChange={(e) =>
                        setEditingTour({
                          ...editingTour,
                          amountOfNights: parseInt(e.target.value),
                        })
                      }
                      className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#2C3333] mb-1">
                      Days (amountOfDays)
                    </label>
                    <input
                      type="number"
                      value={editingTour.amountOfDays}
                      onChange={(e) =>
                        setEditingTour({
                          ...editingTour,
                          amountOfDays: parseInt(e.target.value),
                        })
                      }
                      className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="font-medium text-[#215E5C]">
                      Daily Activities (dailyActivities)
                    </h4>
                    <button
                      onClick={() => handleAddField("dailyActivities")}
                      className="px-3 py-1 bg-[#215E5C] text-[#EAF4F4] rounded-lg hover:bg-[#427D7B] transition-colors text-sm"
                    >
                      + Add Day
                    </button>
                  </div>
                  {editingTour.dailyActivities.map((activity, idx) => (
                    <div
                      key={idx}
                      className="grid grid-cols-4 gap-4 mb-4 relative"
                    >
                      <div className="col-span-1">
                        <label className="block text-sm font-medium text-[#2C3333] mb-1">
                          Day
                        </label>
                        <input
                          type="text"
                          value={activity.day}
                          onChange={(e) => {
                            const updatedActivities = [
                              ...editingTour.dailyActivities,
                            ];
                            updatedActivities[idx].day = e.target.value;
                            setEditingTour({
                              ...editingTour,
                              dailyActivities: updatedActivities,
                            });
                          }}
                          className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-[#2C3333] mb-1">
                          Description
                        </label>
                        <div className="flex gap-2">
                          <input
                            type="text"
                            value={activity.description}
                            onChange={(e) => {
                              const updatedActivities = [
                                ...editingTour.dailyActivities,
                              ];
                              updatedActivities[idx].description =
                                e.target.value;
                              setEditingTour({
                                ...editingTour,
                                dailyActivities: updatedActivities,
                              });
                            }}
                            className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                          />
                          <button
                            onClick={() =>
                              handleRemoveField("dailyActivities", idx)
                            }
                            className="px-3 py-2 bg-[#F4A261] text-[#2C3333] rounded-lg hover:bg-[#F4B583] transition-colors"
                          >
                            ×
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Dynamic fields (locations, exclusions, inclusions, extras) */}
                {["extras", "locations"].map((field) => (
                  <div key={field}>
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="font-medium text-[#215E5C]">
                        {field.charAt(0).toUpperCase() + field.slice(1)} (
                        {field})
                      </h4>
                      <button
                        onClick={() => handleAddField(field)}
                        className="px-3 py-1 bg-[#215E5C] text-[#EAF4F4] rounded-lg hover:bg-[#427D7B] transition-colors text-sm"
                      >
                        + Add {field.slice(0, -1)}
                      </button>
                    </div>
                    {editingTour[field].map((item, idx) => (
                      <div key={idx} className="mb-2">
                        <div className="flex gap-2">
                          <input
                            type="text"
                            value={item}
                            onChange={(e) => {
                              const updatedItems = [...editingTour[field]];
                              updatedItems[idx] = e.target.value;
                              setEditingTour({
                                ...editingTour,
                                [field]: updatedItems,
                              });
                            }}
                            className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                            placeholder={`Enter ${field.slice(0, -1)}`}
                          />
                          <button
                            onClick={() => handleRemoveField(field, idx)}
                            className="px-3 py-2 bg-[#F4A261] text-[#2C3333] rounded-lg hover:bg-[#F4B583] transition-colors"
                          >
                            ×
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className="sticky bottom-0 bg-white p-6 border-t border-[#D9E7E6] flex justify-end gap-3">
                <button
                  onClick={() => {
                    setShowEditModal(false);
                    setEditingTour(null);
                  }}
                  className="px-6 py-2 bg-[#EAF4F4] text-[#2C3333] rounded-lg hover:bg-[#D9E7E6] transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateTour}
                  className="px-6 py-2 bg-[#215E5C] text-[#EAF4F4] rounded-lg hover:bg-[#427D7B] transition-colors"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TourManagement;
